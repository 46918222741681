import apipingafogoweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {

    async find(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('produtoscotacao', { params: { ...where, ...params } }),
        // apipingafogoweb.get('produtoscotacao/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apipingafogoweb.get('produtoscotacao');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    async getAll() {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('produtoscotacao'),
        // apipingafogoweb.get('produtoscotacao/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA SEM CACHE

    async create(_, body) {
      const res = await apipingafogoweb.post('produtoscotacao', body);
      return res;
    },

    async createComVerificacao(_, body) {
      const res = await apipingafogoweb.post('produtoscotacao/verificar', body);
      return res;
    },

    async update(_, body) {
      await apipingafogoweb.put(`produtoscotacao/${body.id}`, body);
    },

    async delete(_, body) {
      await apipingafogoweb.delete(`produtoscotacao/${body.id}`);
    },

    async deleteComVerificacao(_, body) {
      await apipingafogoweb.post('produtoscotacao/verificar/delete', body);
    },

    async findNuvem(_, body) {
      const { data } = await apipingafogoweb.get(
        `produtoscotacao/pingafogo/${body.prcidprodutopingafogo}/${body.prcidempresa}/${body.prcidrepresentante}`,
      );
      return data;
    },

    // async findById(_, { id }) {
    //   const { data } = await apipingafogoweb.get(`produtoscotacao/${id}`);
    //   return data;
    // },

    // async findByWebId(_, { id }) {
    //   const { data } = await apipingafogoweb.get(`produtoscotacao/webid/${id}`);
    //   return data;
    // },

    // async updateById(_, { id }) {
    //   const { data } = await apipingafogoweb.put(`produtoscotacao/${id}`);
    //   return data;
    // },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
