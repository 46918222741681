export const ROUTE_HOME = {
  path: '/',
  name: 'home',
};

export const ROUTE_ABOUT = {
  path: '/about',
  name: 'about',
};

export const ROUTE_SIGN_IN = {
  path: '/sing-in',
  name: 'sign-in',
};

export const ROUTE_COTACAO = {
  path: '/cotacao/:cotacao',
  name: 'cotacao',
};

export const ROUTE_SELECAO = {
  path: '/selecao',
  name: 'selecao',
};
