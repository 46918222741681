import apiusercotacaoparametrosweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {

    async find(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apiusercotacaoparametrosweb.get('usercotacaoparametros', { params: { ...where, ...params } }),
        // apiusercotacaoparametrosweb.get('usercotacaoparametros/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apiusercotacaoparametrosweb.get('usercotacaoparametros');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    async getAll() {
      const [{ data }] = await Promise.all([
        apiusercotacaoparametrosweb.get('usercotacaoparametros'),
        // apiusercotacaoparametrosweb.get('usercotacaoparametros/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA SEM CACHE

    async create(_, body) {
      const res = await apiusercotacaoparametrosweb.post('usercotacaoparametros', body);
      return res;
    },

    async update(_, body) {
      await apiusercotacaoparametrosweb.put(`usercotacaoparametros/${body.id}`, body);
    },

    async delete(_, body) {
      await apiusercotacaoparametrosweb.delete(`usercotacaoparametros/${body.id}`);
    },

    async findNuvem(_, body) {
      const { data } = await apiusercotacaoparametrosweb.get(
        `usercotacaoparametros/${body.prcidrepresentante}/${body.prcidempresa}/${body.prcidcotacaopingafogo}`,
      );
      return data;
    },

    async findByPermitidos(_, { body }) {
      const { data } = await apiusercotacaoparametrosweb.get(`usercotacaoparametros/permissao/${body.useidusuario}/${body.useidempresa}`);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
