import apipingafogoweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {

    async find(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('produtopingafogo', { params: { ...where, ...params } }),
        // apipingafogoweb.get('produtopingafogo/count', { params: where }),
      ]);

      return data;
    },

    async findComValor(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('produtopingafogo/valor/', { params: { ...where, ...params } }),
        // apipingafogoweb.get('produtoscotacao/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apipingafogoweb.get('produtopingafogo');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    async getAll() {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('produtopingafogo'),
        // apipingafogoweb.get('produtopingafogo/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA SEM CACHE
    async findById(_, { id }) {
      const { data } = await apipingafogoweb.get(`produtopingafogo/${id}`);
      return data;
    },

    async findByWebId(_, { id }) {
      const { data } = await apipingafogoweb.get(`produtopingafogo/webid/${id}`);
      return data;
    },

    async updateById(_, { id }) {
      const { data } = await apipingafogoweb.put(`produtopingafogo/${id}`);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
