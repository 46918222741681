import apipingafogoweb from '@/services/api-recebimentoweb';

let findTimeout = null;

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {

    async find(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('cotacaopingafogo', { params: { ...where, ...params } }),
        // apipingafogoweb.get('cotacaopingafogo/count', { params: where }),
      ]);

      return data;
    },

    async findComValor(_, {
      where, ...params
    }) {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('cotacaopingafogo/valor/', { params: { ...where, ...params } }),
        // apipingafogoweb.get('produtoscotacao/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA COM CONTROLE DE CACHE
    async findWithCache({ commit, state }, { force, ttl } = {}) {
      if (!force && state.list?.length > 0) {
        return;
      }

      clearTimeout(findTimeout);

      const { data } = await apipingafogoweb.get('cotacaopingafogo');

      commit('SET_LIST', data);

      if (ttl === Infinity) return;

      findTimeout = setTimeout(() => {
        commit('SET_LIST', null);
      }, ttl ?? 1000 * 60); // TTL -> Time To Live
    },

    async getAll() {
      const [{ data }] = await Promise.all([
        apipingafogoweb.get('cotacaopingafogo'),
        // apipingafogoweb.get('cotacaopingafogo/count', { params: where }),
      ]);

      return data;
    },

    // CONSULTA SEM CACHE

  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
