import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import users from './modules/users';
import empresa from './modules/empresa';
import produtopingafogo from './modules/produtopingafogo';
import produtoscotacao from './modules/produtoscotacao';
import cotacaopingafogo from './modules/cotacaopingafogo';
import usercotacaoparametros from './modules/usercotacaoparametros';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    async $_setup({ dispatch }) {
      await dispatch('auth/$_setup');
    },
  },

  modules: {
    auth,
    users,
    empresa,
    produtopingafogo,
    produtoscotacao,
    cotacaopingafogo,
    usercotacaoparametros,
  },
});
